/* Scss Document */


// Core variables and mixins

//## Brand colors for use across Dibal.

$blue: #2797d7;
$dark-blue: darken($blue, 10%)/*darken(#007BBC, 3%)*/;
$orange: #d46726;
$dark:#303030;

//== Typography

$font-base: "proxima-nova",sans-serif;
$font-condensed: "proxima-nova-condensed", sans-serif;
$font-serif: 'Roboto Slab', serif;
$font-awesome: "FontAwesome", sans-serif;


@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}


// Components
@import "custom/navbar";
 
body{
	font-family: $font-base;
	font-size:16px;
	color:#4d4e53;
	line-height:1.7;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.container-fluid{
	max-width:1300px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight:bold;
	color:$dark;
}

/*.h1, h1 {
	margin-bottom:30px;
}
.h2, h2 {
	margin-bottom:20px;
	margin-top:30px;
}
*/


h1, h2, h3, h4 {
  margin: 1.414em 0 0.5em;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
  font-size: 2.441em;
}

h2 {font-size: 1.953em;}

h3 {font-size: 1.563em;}

h4 {font-size: 1.25em;}

p {margin-bottom: 1.3em;}

.has-line {
    padding-bottom: 15px;
    position: relative;
}
.has-line:after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
	 background: $blue;
    height: 3px;
    width: 30px;
}
.has-line.line-lg:after {
    height: 4px;
    width: 112px;
}
.text-center.has-line:after {
    left: 50%;
    margin-left: -15px;
}
.text-center.line-lg:after {
    margin-left: -56px;
}
.lead {
	font-family:$font-serif;
	@media (min-width: 768px){
		font-size:20px;
		line-height:1.6em;
		}
}


a, button.btn{ 
 	@include transition(all 0.3s ease 0s);
}
a{ 
	color:$blue;
	text-decoration: none;
}

a:focus, a:hover{
	text-decoration: none;
	color:darken($blue, 15%);
}

a:focus, 
a:active, 
.btn.active.focus, 
.btn.active:focus, 
.btn.focus, 
.btn.focus:active, 
.btn:active:focus, 
.btn:focus, 
li:focus, 
li:active{ 
	outline: none;
}

a.link{
	text-transform:uppercase;
	display:inline-block;
	margin:15px 0;
	font-size:11px;
	font-weight:700;
	letter-spacing:1pt;
}

.btn{
	border-radius: 0;
	white-space: normal;
}

.btn-blue{
	 background-color: $blue;
    border-color: $blue;
	 color:#fff;
	 &:hover {
    	background-color: darken($blue, 15%);
	   border-color: darken($blue, 15%);
	   color:#fff;
  }
}
.btn-dark-blue{
	 background-color: $dark-blue;
    border-color: $dark-blue;
	 color:#fff;
	 &:hover {
    	background-color: $blue;
	   border-color: $blue;
	   color:#fff;
  }
}

.btn-orange{
	 background-color: $orange;
    border-color: $orange;
	 color:#fff;
	 &:hover {
    	background-color: darken($orange, 15%);
	   border-color: darken($orange, 15%);
	   color:#fff;
  }
}

.btn-dark{
	 background-color: $dark;
    border-color: $dark;
	 color:#fff;
	 &:hover {
    	background-color: lighten($dark, 3%);
	   border-color: lighten($dark, 3%);
	   color:#fff;
  }
}

.btn-lg{
	padding:15px 40px;
	font-size:16px;
	font-weight:500;
	/*letter-spacing:1.5pt;
	text-transform:uppercase;*/
}
.bg-blue{
	background:$blue;
	color:#fff;
}
.box-contact h3.h3-btn {
  margin: 0;
}

/* IMAGES */

img{
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}
figure img{
	margin:0 auto;
}

.fa-left {
	margin-right:5px;
}
.fa-right {
	margin-left:5px;
}

/* img hover effect */

.rollover{
	transition: background 0.3s ease 0s;
	position:relative;
	overflow:hidden;
}
.rollover:hover {
    background-color: $dark;
}
.rollover-download:before {
    content: "\f01a";
    font-family:$font-awesome;
}
.rollover:hover:before {
    opacity: 1;
    visibility: visible;
}
.rollover img {
    opacity: 1;
	  -webkit-backface-visibility: hidden;
	  backface-visibility: hidden;
    transform: scale(1.05) translateZ(0px);
    transition: all 0.6s ease 0s;
}
.rollover:hover img {
	opacity: 0.7;
	 -webkit-backface-visibility: hidden;
    transform: scale(1) translateZ(0px);
}


/* Skew images */


.skew-img{
	position:relative;
	overflow:hidden;
}
.bottom-border {
    background-color: #F1F1F1;
/*  bottom: -70px;
    height: 70px;*/    
	 bottom: -20%;
    height: 20%;
	 left: -10%;
    position: absolute;
    transform: rotate(-8deg);
    transform-origin: 0 0 0;
    width: 120%;
    z-index: 20;
}
.top-border {
    background-color: #F1F1F1;
    /*height: 70px;*/
	 height: 20%;
    left: -10%;
    position: absolute;
    top: 0;
    transform: rotate(-8deg);
    transform-origin: 0 0 0;
    width: 120%;
    z-index: 20;
}

.bg-light{
	.bottom-border {
		 background-color: #F7F7F7;
	}
	.top-border {
		 background-color: #F7F7F7;
	}
}

/* FORMS */

.form-control{
	-webkit-appearance: none; /* for box shadows to show on iOS */
}
.form label{
	text-transform:uppercase;
	font-size:13px;
	letter-spacing:0.5pt;
}
.form .checkbox label{
	text-transform: none;
	font-size:14px;
	letter-spacing: normal;
}
.form .form-control{
    border: 1px solid #ddd;
    border-radius: 0px;
    box-shadow: none;
    height: 48px;
    padding: 12px 14px;
	 font-size:16px;
}
.form textarea.form-control{
	 height: auto;
}
.form .form-control:focus{
	box-shadow: none;
	border:1px solid $blue;
	/*border-left:6px solid $blue;*/
}
.form .form-control::-moz-placeholder {
   /* color: #999999;*/
    opacity: 1;
}
.form .checkbox{
    font-size:14px;
}
.form button.btn{
	height:50px;
	font-weight:bold;
	font-size:16px;
	letter-spacing:0.1em;
}


/* Form lateral */

.fancy-form .form-group {
	margin-bottom: 30px;
}
.fancy-form label {
	color: #fff;
}
.fancy-form .form-control {
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid #6E7989;
	border-radius: 0;
	box-shadow: none;
	padding: 6px 0;
	font-style: normal;
	color: #fff;
	height: 40px;
	font-size: 16px;
}
.fancy-form textarea.form-control {
	height: auto;
}
.fancy-form .form-control:focus {
	transition: transform 0.3s ease 0s;
	border-bottom: 1px solid $blue;
	border-right:none;
	border-left:none;
	border-top:none;
}

.fancy-form ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
	 opacity:1;
	 font-weight:bold;
}
.fancy-form ::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
	 opacity:1;
	 font-weight:bold;
}
.fancy-form :-ms-input-placeholder { /* IE 10+ */
    color: #fff;
	 opacity:1;
	 font-weight:bold;
}
.fancy-form :-moz-placeholder { /* Firefox 18- */
    color: #fff;
	 opacity:1;
	 font-weight:bold;
}

.fancy-form .checkbox label {
	text-transform: none;
}
.fancy-form .form-control:focus::-moz-placeholder {
 	opacity: 0;
}


.form-lateral .btn{
    text-transform:uppercase;
	 margin-top:26px;
}

/* Form footer */

/*.footer-newsletter .form .form-control{
   border-left:6px solid #fff;
	border-bottom:none;
	border-top:none; 
	border-right:none;
}
.footer-newsletter .form .form-control:focus{
	border-left:6px solid $dark-blue;
	border-bottom:none;
	border-top:none; 
	border-right:none;
}
*/

/* Form Newsletter */

.form-inline .button{
	display:inline-block;
	height:48px;
	margin:0;
	vertical-align:top;
	padding:15px 20px;
	width:auto;
	margin-bottom:15px;
	width:180px;
}
.form-inline .form-group{
	margin-bottom:15px;
}
@media (min-width: 768px) {
	.form-inline .form-control{
		width:280px;
	}
}
@media (max-width: 767px) {
	.form-inline .button{
		display:block;
		width:100%;
	}
}

/* Form validation */

label.error {
    font-weight: normal;
	 text-transform:none;
	 color:#DD3D3D;
	 font-size:14px;
}
.alert > p, .alert > ul{
	line-height: normal;
}
.alert{
	border-radius:0; 
	font-size:15px;
	padding:1.5em;
	p:last-child{
		margin-bottom:0;
	}
}
.alert-danger{
	color:#dd3d3d;
}
.alert-success{
	background-color: #D3E4ED;
	border-color: #AAD2E8;
	color: $dark-blue;
	font-size:16px;
	text-align:center;
}
#divdesc.alert{
	text-align: center;
	padding: 1em;
	@media (max-width: 500px) {
		padding: 0;
	}
}
#divpdf.alert{
	text-align: center;
	padding: 0;
	margin-bottom: 10px;
}


/* TABLE */

.table > thead > tr > th, 
.table > thead > tr > td, 
.table > tbody > tr > th, 
.table > tbody > tr > td, 
.table > tfoot > tr > th, 
.table > tfoot > tr > td{
	vertical-align:middle;
}

/* PAGINATION */

.pagination{
	border-radius:0;
	/*border:1px solid #ddd;*/
	margin-top:40px !important;
}
.pagination > li:first-child > a,
.pagination > li:last-child > a{
	border-radius:0;
}
.pagination > li:before{
	content:"";
}

.pagination > li > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover{
	 font-size: 14px;
    line-height: normal;
    padding: 10px 18px;
	 border:0;
	 color:$dark;
	 background-color:transparent;
}
.pagination > .active > a, 
.pagination > .active > a:focus, 
.pagination > .active > a:hover{
	background:$blue;
	color:#fff;
}

/* HELPER CLASSES */

.made-table{
	display: table;
	height: 100%;
	width: 100%;
}
.made-table-cell{
	display: table-cell;
	vertical-align: middle;
}
.padding-bottom{
	padding-bottom:90px;
}
.padding{
	padding:60px 0;
}
.nopadding-top{
	padding-top: 0!important;
}




/* HEADER
-------------------------------------------------- */

/* Top header */

.top-header{
	background-color:#fff;
	.languages{
		float:left;
		margin:0;
	}
	.languages li{
		display:inline-block;
		line-height:70px;
	}
	.languages a{
		line-height:14px;
		font-size:14px;
		color:#666;
		&.active{
			color:$blue;
		}
	}
	.brand{
		width:126px;
		position:absolute;
		left:0;
		right:0;
		top:12px;
		margin:0 auto;
		img{ max-width:126px;}
	}
	.secondary-links{
		a{ color:#666;}
		display:none;
		float:right;
		text-align:right;
		.social{
			display:inline-block;
			margin-bottom:5px;
		}
		.social a{
			display:inline-block;
			padding:4px;
		}
		.area-privada{
			font-size:14px;
		}
	}
	.navbar-toggle{
		border:none !important;
		margin:0 -15px 0 0;
		background-color:$dark;
		border-radius: 0;
		height:70px;
		width:70px;
		.icon-bar{
			margin-left:auto;
			margin-right:auto;
			background-color:#fff;
		}
	}
}
@media (min-width: 992px){
	.top-header{
		padding:26px 0;
		position:relative;
		.languages li{
			line-height:77px;
		}
		.brand{
			top:26px;
			width:210px;
			img{ max-width:210px;}
		}
		.secondary-links{ display:block;}
	}
}


/* navbar */

.dropdown-menu{
	box-shadow:none;
	border-radius:0;
}
#desktop-nav .dropdown-full .dropdown-menu{
	min-height:440px;
}

/* #mobile-nav */

#mobile-nav{
	.dropdown-menu, .navbar{
		min-height:0;
	}
	.navbar.navbar-inverse{
		border-bottom:1px solid #eee;
	}
	.secondary-links{
		padding:15px;
		overflow:hidden;
		line-height:normal;
		a{
			padding:0 5px;
			color:#888;
		}
		.social{
			float:left;
			margin:0;
		}
		.area-privada{
			float:right;
			margin:0;
			font-size:14px;
		}
	}
}
#mobile-nav .navbar-nav{
	float:none;
	box-shadow:0 1px 0 rgba(255, 255, 255, 0.1) inset;
}
#mobile-nav .navbar-nav > li{
	border-bottom:1px solid #414141;
}
#mobile-nav .navbar-nav  li{
   position: relative;
	float:none;
	display:block;
}
#mobile-nav .navbar .navbar-collapse .navbar-nav li a{
    display: block;
    padding: 15px;
    position: relative;
	 margin:0;
	 color:#fff;
	 line-height:normal;
}
#mobile-nav .navbar-nav > li > a, 
#mobile-nav .navbar-nav > li > .link-dropdown > a{
	text-transform:uppercase;
	font-weight:600;
	font-size:14px;	
}
#mobile-nav li a:hover,
#mobile-nav li a:focus{
	background:none;
}
#mobile-nav li.has-submenu > .link-dropdown > a{
	padding-right:70px;
}
#mobile-nav li.active > a,
#mobile-nav li.active > .link-dropdown > a{
    background:$blue;
	 color:#fff;
}
#mobile-nav li.active > .link-dropdown > .arrow:before{
	 color:#fff;
}
#mobile-nav > li{
	border-bottom:1px solid #eee;
}
#mobile-nav li ul{
	background:lighten($dark, 5%);
	list-style-type:none;
	margin:0;
	padding:0;
}
#mobile-nav li ul li{
	border-bottom: none;
}
#mobile-nav li ul li a{
	border-top:1px solid rgba(255, 255, 255, 0.03);
}
#mobile-nav li ul li.active a{
	 background:$blue;
}

#mobile-nav li ul li ul{
	background:lighten($dark, 10%);
}
.link-dropdown{
	position:relative;
}
#mobile-nav .arrow{
	position:absolute;
	color:#fff;
	right:0;
	top:0;
	height:100%;
	width:70px;
	text-align:center;
	cursor:pointer;
}
#mobile-nav .arrow:before{
	position:absolute;
	content:"\f107";
	font-family:"FontAwesome", sans-serif;
	color:#fff;
	font-size:18px;
	line-height:18px;
	right:0;
	top:50%;
	left:0;
	right:0;
	margin:-8px auto 0;
	transform: rotate(0deg);
	@include transition(all 0.3s ease 0s);
}
#mobile-nav .arrow.collapsed:before{
	transform: rotate(-90deg);
	@include transition(all 0.3s ease 0s);
}

/* #desktop-nav */

#desktop-nav .navbar-nav {
	 float:none;
	 text-align:center;
}
#desktop-nav .navbar-nav > li {
    /*border-right: 1px solid #404040;*/
	 display:inline-block;
	 margin-left:-4px;
	 float:none;
}
.navbar .navbar-nav > li > a{
	text-transform:uppercase;
	font-weight:500;
	font-size:14px;
	/*font-family:$font-condensed;*/
	letter-spacing:0.2pt;
	padding-left:20px;
	padding-right:20px;
}

#desktop-nav .navbar-nav > li:last-child {
    border-right: medium none;
}
#desktop-nav .nav-tabs li a{
	padding:12px 20px;
}
#desktop-nav .nav-tabs li{
	border-bottom: 1px solid rgba(255,255,255,0.1);
}
@media (min-width: 992px) and (max-width: 1110px){
	.navbar .navbar-nav > li > a{
		padding-left:17px;
		padding-right:17px;
		font-size:13px;
	}
	.navbar .caret{
		margin-left:5px !important;
	}
	#desktop-nav .navbar-nav {
	 float:left;
	}

}

@media (min-width: 992px){
	#desktop-nav{
		display:block;
		position:relative;
		z-index:40;
	}
	#mobile-nav{
		display:none;
	}
}
@media (max-width: 991px){
	#desktop-nav{
		display:none;
	}
	#mobile-nav{
		display:block;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
    }
    .navbar-header .collapse, .navbar-toggle {
        display:block !important;
    }
	.container-fluid > .navbar-collapse, 
	.container-fluid > .navbar-header, 
	.container > .navbar-collapse, 
	.container > .navbar-header {
		 margin-left: -15px;
		 margin-right: -15px;
	}
}

/* Buscador */
	
#sb-search{  position: absolute; top: 0; right: 0; }
.sb-search {
	width: 0%;
	min-width: 50px;
	height: 50px;
	overflow: hidden;
	-webkit-transition: width 0.3s;
	-moz-transition: width 0.3s;
	transition: width 0.3s;
	-webkit-backface-visibility: hidden;
}
.sb-search-input {
	position: absolute;
	top: 0;
	right: 0;
	border:none;
	border-radius:0;
	box-shadow:none;
	background: $dark;
	width: 100%;
	/*max-width:350px;*/
	height: 50px;
	z-index: 10;
	font-size: 16px;
	color: #fff;
}
input[type="search"].sb-search-input {
	-webkit-appearance: none;
	-webkit-border-radius: 0px;
	border-radius: 0px;
}
.sb-search-input::-webkit-input-placeholder {
	color: #888;
}
.sb-search-input:-moz-placeholder {
	color: #888;
}
.sb-search-input::-moz-placeholder {
	color: #888;
}
.sb-search-input:-ms-input-placeholder {
	color: #888;
}
.sb-search-icon,
.sb-search-submit  {
	width: 50px;
	height: 50px;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0;
	margin: 0;
	line-height: 50px;
	text-align: center;
	cursor: pointer;
}
.sb-search-submit {
	background: $dark; /* IE needs this */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
    filter: alpha(opacity=0); /* IE 5-7 */
    opacity: 0;
	color: transparent;
	border: none;
	outline: none;
	z-index: -1;
}
.sb-search-icon {
	color: #fff;
	background: $dark;
	z-index: 90;
	font-size: 16px;
}
.sb-search.sb-search-open/*,
.no-js .search*/ {
	width: 100%;
}
.sb-search.sb-search-open .sb-search-icon,
.no-js .sb-search .sb-search-icon {
	background: $blue;
	color: #fff;
	z-index: 11;
}
.sb-search.sb-search-open .sb-search-submit,
.no-js .sb-search .sb-search-submit {
	z-index: 90;
}
@media (max-width: 991px) {
	#sb-search-mobile{  
		position: relative;
		width:100%; 
	}
	.sb-search-icon{
		background:#454545;
	}
	.sb-search-icon,
	.sb-search-submit  {
		width: 70px;
	}
}


/* FOOTER
-------------------------------------------------- */

#footer{
	background:$blue;
	color:#fff;
	font-size:15px;
	font-weight:500;
}
#footer h4{
	color:#fff;
	text-transform:uppercase;
	font-size:15px;
	margin-bottom:20px;
	letter-spacing:0.06em;
}

#footer ul{
	padding:0;
	margin:0;
	list-style-type:none;
}
#footer a{
	color:#fff;
}

/* Footer top */

.footer-top{
	padding:60px 0;
}
.logo-footer{
	margin-bottom:20px;
}
.footer-links li{ 
	margin-bottom:5px;
}
.footer-links li a:hover{ 
	opacity:0.7;
}

/* Footer bottom */

#footer{
	.footer-bottom{
		background:$dark-blue;
		overflow:hidden;
		padding:18px 0;
		font-size:13px;
	}
	.footer-bottom a{
		opacity:0.6;
	}
	.footer-bottom a:hover{
		opacity:1;
	}
	.legal-links{
		float:left;
	}
	.legal-links > li + li::before {
		 color: #ccc;
		 content: "| ";
		 padding: 0 5px;
	}
	.private-link{
		float:right;
	}
	@media (max-width: 850px) {
		.footer-bottom{
			text-align:center;
		}
		.legal-links,
		.private-link{
			float:none;
		}
		.legal-links{
			margin-bottom:20px;
			border-bottom:1px solid rgba(255, 255, 255, 0.12);
			padding-bottom: 15px;
		}
	}
}

@media (max-width: 767px) {
	#footer{
		text-align:center;
		h4{
			margin-top:40px;
			margin-bottom:10px;
		}
		.footer-top{
			padding:40px 0;
		}
		.logo-footer{
			margin-left:auto;
			margin-right:auto;
		}
		.footer-contact{
			border-bottom:1px solid rgba(255, 255, 255, 0.12);
			padding-bottom: 20px;
		}
	}
}


/* NEWSLETTER SECTION
-------------------------------------------------- */

.newsletter-section{
	padding:60px 0;
	h4{
		text-transform:uppercase;
		letter-spacing:0.08em;
		font-weight:700;
		margin-bottom:30px;
	}
	.alert-success {
	    color: #00ae54;
    	background-color: #bfe9df;
   		border-color: #c3e6cb;
   		font-weight: 500;
	}
}

/* BREADCRUMB
-------------------------------------------------- */
#breadcrumb{
	/*background:#fff;*/
	border-bottom:1px solid #eee;
	margin-bottom:30px;
	.breadcrumb{
		 background-color: transparent;
		 border-radius: 0px;
		 padding:0;
		 margin:10px 0 5px;
		 font-size:14px;
	}
	.breadcrumb li{
		margin-bottom:5px;
	}
	.breadcrumb a {
		 color: $blue;
	}
	.breadcrumb > .active {
		 color: #555;
	}
}


/* MASTHEAD
-------------------------------------------------- */
.masthead {
  padding: 40px 0;
  position: relative;
  color: #fff;
  text-align: center;
  background-color: $dark;
  background-repeat: no-repeat;
  background-position: center 30%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url(../../../media/imagenes/soluciones-industriales-banner.jpg);
  box-shadow: 0 4px 1px rgba(0, 0, 0, 0.07) inset;
  z-index: 1;
}
.masthead:after {
  background: rgba(6, 54, 77, 0.3);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.masthead h1 {
  margin: 0;
  text-transform: uppercase;
  color: #fff !important;
  /*font-size:42px;*/
}
.masthead .subtitle {
  margin-top: 18px;
  font-size:20px;
  font-weight:300;
  line-height:1.5em;
  font-family:$font-serif;
	@media (max-width: 767px) {
		font-size:16px;
		font-weight: 400;
	}
}
.masthead .category {
	text-transform:uppercase;
	font-size:13px;
	font-weight:500;
	letter-spacing:0.5pt;
	display:block;
	margin-bottom:10px;
}

@media (min-width: 768px) {
  .masthead {
    padding: 90px 0;
  }
}


#facilities-header.masthead{
  background-image: url(../../../media/imagenes/video-dibal-bg.jpg);
  background-position: center 60%;
}

#idi-header.masthead{
 background-image: url(../../../media/imagenes/quienes-somos/innovacion-dibal.jpg);
  background-position: center 50%;
}

#careers-header.masthead{
  background-image: url(../../../media/imagenes/quienes-somos/work-with-us-dibal.jpg);
  background-position: center 60%;
}

#quality-header.masthead{
  background-image: url(../../../media/imagenes/quienes-somos/calidad-dibal.jpg);
  background-position: center 50%;
}
#sector-header.masthead:after {
  background: rgba(2, 17, 24, 0.61);
}


/* GENERAL CONTENT
-------------------------------------------------- */


.row.no-gutters {
  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.row.gutter-sm {
	margin-left: -10px;
	margin-right: -10px;
  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

main#content{
	padding-bottom:90px;
	background:#f1f1f1;
}
main#content.no-padding{
	padding-bottom:0;
}

/* Main Content */


.page h1,
.page .h1{
	text-transform:uppercase;
	font-weight:900;
	text-align:center;
	line-height:1.1em;
	font-size:28px;
	margin-top:0;
	@media (min-width: 480px) {
		font-size:32px;
	}
	@media (min-width: 768px) {
		font-size:36px;
		/*line-height:0.9em;*/
	}
	@media (min-width: 992px) {
		font-size:40px;
	}
}

main.page p{
	margin:0 0 24px;
}
main.page ul {
   /* list-style: outside none none;*/
	 margin:0 0 24px;
}
main.page li {
    margin-bottom: 15px;
    position: relative;
	 line-height:1.56em;
}
main.page li:before {
   /* color: $blue;
    content: "\f111";
    display: inline-block;
    font-family: $font-awesome;
    font-size: 6px;
	 line-height:6px;
    font-weight: 700;
    height: 0;
    left: -20px;
    position: absolute;
    top: 12px;*/
}


/* Section Header (Index, product...)*/


.page-header{
	margin:0;
	border:none;
	padding-top:10px;
	padding-bottom:20px;
	@media (min-width: 480px) {
		padding-top:20px;
		padding-bottom:35px;
	}
	@media (min-width: 768px) {
		padding-top:30px;
		padding-bottom:45px;
	}
}

.page-section{
	padding-top:40px;
	padding-bottom:40px;
	@media (min-width: 768px) {
		padding-top:90px;
		padding-bottom:90px;
	}
	/*@media (min-width: 992px) {
		padding-top:110px;
		padding-bottom:110px;
	}*/
}

.section-title{
	margin-top: 10px;
	margin-bottom:30px;
	text-transform:uppercase;
	letter-spacing:0.02em;
	text-align:center;
	font-size:22px;
	@media (min-width: 768px) {
		margin-bottom:60px;
		font-size:24px;
	}
	@media (min-width: 992px) {
		margin-bottom:70px;
		font-size:26px;
	}
}

.bg-light{
	background:#f7f7f7;
	border-top:1px solid #eee;
}


/* BOX ITEMS */

.box-item {
    overflow: hidden;
    position: relative;
}
.box-item a {
    display: block;
    overflow: hidden;
	 position: relative;
}
.box-item figure {
    display: block;
    position: relative;
    width: 100%;
    z-index: 9;
	 overflow:hidden;
	 background:#D6D6D6;
	 padding:20px 10px;
	 font-weight:600;
	 font-size:13px;
}
.box-item figure::after {
    backface-visibility: hidden;
	 background:rgba(0, 0, 0, 0) radial-gradient(ellipse at center center , rgba(102, 102, 102, 0) 48%, rgba(102, 102, 102, 0.2) 100%) repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
}
.box-item figure img {
    backface-visibility: hidden;
    transform: scale(1);
    transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1) 0s;
	 margin:0 auto;
}
.box-item .item-info{
	padding:20px 15px;
	background:#fff;
	text-align:center;
	border-bottom:2px solid #E8E8E8;
}
.box-item .item-info h3{
	font-weight:700;
	font-size:16px;
	line-height:1.3;
	text-transform:uppercase;
	margin:0; 
}
.box-item .item-info .item-desc{
	font-size:14px;
	line-height:1.4em;
	margin-top:10px;
	color:#666;
}
.box-item .item-info .item-desc p{
	margin:0;
}

/* Box-items hover*/

.box-item .hover {
    /*transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;*/
	 @include transition(all 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s);
    background: rgba(39, 154, 215, 0.8) none repeat scroll 0 0;
    height: 36px;
	 bottom:-36px;
    left: 0;
	 right:0;
    margin:0 auto;
    position: absolute;
    width: 114px;
    z-index: 10;
	 color:#fff;
	 text-align:center;
	 line-height:36px;
}
.box-item:hover .hover {
    bottom:0;
}
.box-item:hover a img {
    transform: scale(1.08);
}


/* BOX INNER */

.box-inner {
    overflow: hidden;
    position: relative;
}
.box-inner a {
    display: block;
    overflow: hidden;
	 position: relative;
}
.box-inner figure {
    display: block;
    position: relative;
    width: 100%;
    z-index: 9;
	 overflow:hidden;
}
.box-inner figure::after {
	 background: rgba(0, 0, 0, 0) linear-gradient(transparent, rgba(0, 0, 0, 0.75)) repeat scroll 0 0;
    backface-visibility: hidden;
    bottom: 0;
    content: "";
    height: 50%;
    left: 0;
    position: absolute;
    width: 100%;
}
.box-inner figure img {
    backface-visibility: hidden;
    transform: scale(1);
    transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1) 0s;
	 width:100%;
}

.box-inner .item-info {
	background:none;
	backface-visibility: hidden;
	bottom: 0;
	color: #fff;
	font-size: 15px;
	left: 0;
	letter-spacing: 0.1px;
	line-height: 1.15em;
	/*padding: 30px;*/
	padding:7%;
	position: absolute;
	width: 100%;
	z-index: 11;
	.title {
		color: #fff;
		font-size:15px;
		text-transform:uppercase;
		line-height:1.3em;
		font-weight:700;
		letter-spacing:0.06em;
		margin:0;
	}
}
.box-inner .item-info .item-desc{
	font-size:14px;
	line-height:1.4em;
	margin-top:10px;
}
.box-inner .item-info .item-desc p{
	margin:0;
}


/* Box-inner hover*/

.box-inner .hover {
	@include transition(all 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s);
   /* transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;*/
}
.box-inner .hover {
   /* background: rgba(8, 79, 156, 0.6) none repeat scroll 0 0;*/
	 background: rgba(0, 55, 85, 0.45);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
}
.box-inner .hover::before {
    background: $blue none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    transition: width 0.2s ease 0s;
    width: 0;
}
.box-inner:hover .hover::before {
	 width: 100%;
}
.box-inner:hover .hover {
    opacity: 1;
}
.box-inner:hover a img {
    transform: scale(1.08);
}


@media (max-width: 600px) {
	.box-inner.col-xs-6{
		width:100%;
	}
}
@media (max-width: 480px) {
	.box-item.col-xs-6{
		width:100%;
	}
}


/* item list */

.item-list {
	.box-item, .box-inner{
		margin-bottom:30px;
	}
}
.row.gutter-sm {
	.box-item, .box-inner{
		margin-bottom:20px;
	}
}



/* HOME
-------------------------------------------------- */

/* Solutions home */

/*#solutions-home h1{
	text-align:center;
	background:$blue;
	color:#fff;
	padding:20px;
	margin:0;
}
*/
#solutions-home.container-fluid{
	max-width: none;
}
.hero-banner{
	&.box-inner .item-info{
		padding: 30px 30px 50px 50px;
		h2.title{
			font-size:22px;
			font-weight:bold;
			letter-spacing:0.5pt;
			text-transform:uppercase;
		}
	}
}

/* About home */

#about-home{
	/*background-image: url(../../../media/imagenes/video-dibal-bg.jpg);
	background-repeat: no-repeat;
	background-position: center 50%;
	background-color: #e9e9f1;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;*/
	background-color: #fff;
	padding:90px 0;
}
#about-home h1{
	text-transform:uppercase;
	font-weight:900;
}
#about-home .lead{
	/*font-size:19px;*/
	line-height:1.7;
}
.btn-video{
	position:relative;
	margin:40px 0;
	text-align:left;
	display:inline-block;
	padding-left:85px;
	height:65px;
	line-height:65px;
	text-transform:uppercase;
	font-size:14px;
	letter-spacing:0.1em;
	&:before{
		content:"\f04b";
		display:inline-block;
		height:65px;
		width:65px;
		border:2px solid $blue;
		text-align:center;
		font-family:$font-awesome;
		font-size:18px;
		color:$blue;
		line-height:60px;
		position:absolute;
		left:0;
		vertical-align:middle;
	}
}

/* Productos destacados */

#products-home{
	/*padding:90px 0;*/
}
#products-home h2{
	/*text-transform:uppercase;
	margin-bottom:60px;*/
}


/* Noticias home */

#news-home{
	/*border-top:1px solid #ddd;
	padding:90px 0;*/
}
#news-home h2{
	/*text-transform:uppercase;
	margin-bottom:60px;*/
}


/* QUIENES SOMOS
-------------------------------------------------- */


/* QUIENES SOMOS */


#about h2{
	text-transform:uppercase;
	color:$blue;
	letter-spacing:0.1em;
	font-size:20px;
	font-weight:600;
}


/* INSTALACIONES */

#facilities{
	.location h3{
		font-size:20px;
	}
}

#facilities-locations{
	margin-bottom: 30px;
	.col-sm-6:nth-of-type(2n+1){
		clear:both;
	}
}



/* INNOVACION */


#idi h2{
	text-transform:uppercase;
	color:$blue;
	letter-spacing:0.1em;
	font-size:20px;
	font-weight:600;
}

/* CALIDAD */

#quality-certifications{
	padding:60px 0;
}

#quality-certifications .quality-policy{
	margin-bottom: 2rem;
	.certificate {
		display: inline-block;
		width: auto;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
}

#quality-certifications h1{
	margin-bottom: 30px;
	/*font-size:44px;*/
	text-align:left;
}
#quality-certifications .lead{
	line-height:1.7em;
	font-size:19px;
}


#quality-certifications h2{
	text-transform:uppercase;
	color:$blue;
	letter-spacing:0.1em;
	font-size:20px;
	font-weight:600;
	&.h1{
		margin-bottom: 30px;
		text-align:left;
		font-size: 40px;
		color:$dark;
		font-weight: 900;
	}
}
#quality-certifications .list-certificates-brands{
	margin:20px 0;
}
.certificate-logo{
	background:#fff;
	padding:20px;
}

#quality-certifications .list-certificates{
	display: flex;
	flex-wrap: wrap;
	margin:40px 0 0;
}
.certificate{
	text-align:center;
	color:$dark;
	display:block;
	margin-bottom:30px;
}
.certificate .certificate-img{
	border:1px solid #ddd;
	padding:15px;
	position:relative;
	overflow:hidden;
}
.certificate h3{
	font-size:15px;
	text-transform:uppercase;
	margin:24px 0 15px;
}
.certificate-info{
	font-size:13px; 
	line-height:1.4em;
}
.certificate .hover {
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    background: rgba(39, 154, 215, 0.8) none repeat scroll 0 0;
    height: 46px;
	 bottom:-46px;
    left: 0;
	 right:0;
    margin:0 auto;
    position: absolute;
    width: 114px;
    z-index: 10;
	 color:#fff;
	 text-align:center;
	 line-height:46px;
}
.certificate .hover:before {
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;
	 content:"";
}
.certificate:hover .hover {
    bottom:0;
}

@media (max-width: 991px) {
/* 	.list-certificates .col-md-3:nth-of-type(2n+1){
		clear:both;
	} */
}
@media (max-width: 480px) {
	.list-certificates .col-xs-6,
	.list-certificates-brands .col-xs-6 {
		width:100%;
	}
}

#quality-control{
	background:#F7F7F7;
	h3{
		text-transform:uppercase;
		color:$blue;
		letter-spacing:0.05em;
		font-size:16px;
		font-weight:600;
		margin:30px 0 15px;
	}
	.col-md-6{
		margin-bottom:40px;
	}
	
}

/* DECLARACIONES DE CONFORMIDAD */

#declarations-conformity{
	.page-header{
		margin-bottom:0;
	}
}

.box-links{
	background:rgba(255,255,255,0.45);
	border:1px solid #eee;
	margin-bottom:30px;
	display:block;
	padding:10% 4%;
	text-align:center;
	color:$dark;
	h3{
		text-transform:uppercase;
		font-size:21px;
		margin-bottom:40px;
		span{
			font-size:16px;
			font-weight:normal;
			margin-top:10px;
			display:block;
		}
	}
	.link{
		color:$blue;
		font-weight:bold;
		font-size:15px;
	}
	&:hover{
		background:rgba(255,255,255,0.9);
	}
}
#declarations-conformity-detail{
	h2{
		text-transform:uppercase;
		font-weight:300;
		margin-top:30px;
		&.padding-top{
			padding-top:60px;
		}
	}
}

table.list-declarations > thead > tr > th{
	color:$dark;
	text-transform:uppercase;
	letter-spacing: 0.02em;
	@media (min-width: 768px) {
		font-size:19px;
	}
}
table.list-declarations{
	 th,td{
		 padding:15px 8px !important;
	 }
	tbody th{
		width: 85%;
		font-weight: 500;
	} 
	tbody td{
		text-align: center;
		a { font-weight: 600}
	}
}


/* DIBAL EN EL MUNDO */

/* TRABAJA CON NOSOTROS */

#careers #contact-form{
		margin:60px 0 90px;
}



/* SOLUCIONES
-------------------------------------------------- */

#solutions {
	 h1{
		text-align:left;
	}
}

/*.list-solutions{
	margin-top:60px;
}
*/
.solution{
	/*background-color: #a6a7b1;*/
	color:#fff;
	margin-bottom:30px;
}
.solution .solution-info{
	overflow:hidden;
	position:relative;
	padding:10px 30px 54px;
	background-color: #a6a7b1;
	color:#fff;
}
.solution .solution-info .bottom-border{
	 bottom: -30%;
    height: 30%;
}

.solution.family .solution-info{
	background-color: $blue;
}

.solution .solution-info h3{
	margin:14px 0;
	color:#fff;
	text-transform: uppercase;
	font-size: 19px;
	letter-spacing: 0.02em;
	
}
.solution .solution-info .solution-desc{
	font-size:15px;
	line-height:1.4em;
	margin-bottom:16px;
}

/* Skew-border */

.solution .solution-img .bottom-border{
	background-color: #a6a7b1;
}
.solution.family .solution-img .bottom-border{
	background-color: $blue;
}


@media (min-width: 768px) and (max-width: 991px) {
	.solution .solution-info{
		padding-left:18px;
		padding-right:18px;
	}
}
@media (max-width: 600px) {
	.solution .solution-info{
		padding-left:18px;
		padding-right:18px;
	}
}
@media (max-width: 480px) {
	.solution.col-xs-6{
		width:100%;
	}
}


/* PRODUCTOS */

#product-detail .product-contact-form #form-detail .alert-success{
	color: #00ae54;
    background-color: #bfe9df;
   	border-color: #c3e6cb;
   	font-weight: 500;
   	p{
   		margin: 0;
   	}
}

/* FAMILIA */

.link-title{
	overflow:hidden;
	color:$dark;
	display:block;
	border-bottom:2px solid #eee;
	padding-bottom:20px;
	margin:60px 0 40px;
}
.link-title:hover{
	color:$dark;
}
.link-title h2{
	float:left;
	margin:0;
	font-weight:300;
	text-transform:uppercase;
}
.link-title span{
	float:right;
	margin-top:5px;
	font-weight:600;
	font-size:15px;
	@include transition(all 0.3s ease 0s);
	letter-spacing:0.2pt;
}
.link-title span .fa{
	font-size:16px;
}
.link-title:hover span{
	color:$blue;
}


.subfamilia .intro{
	margin-bottom:30px;
}

/* PRODUCTOS FICHA */


#product-detail{
	padding-bottom:60px;
}
.product-image{
	margin-bottom: 40px;
}
.product-gallery img{
	margin:0 auto;
}
img.img-border{
	border: 1px solid #e1e1e1;
}

/* Img carousel */

#product-detail-carousel{
	position:relative;
	margin:0 auto;
}
#product-detail-carousel .owl-wrapper-outer{
	/*border: 1px solid #e1e1e1;*/
}
#product-detail-carousel .item img{
	margin:0 auto;
}
#product-detail-carousel .owl-controls{
	/*bottom:20px;
	left:0;
	right:0;
	position: absolute;*/
	margin-top:20px;
}
#product-detail-carousel .owl-controls .owl-page span{
	background:$blue;
}
#product-detail-carousel .owl-controls .owl-buttons{
	display:none;
}

/*#product-detail-carousel .owl-prev:before,
#product-detail-carousel .owl-next:before {
	color: #666;
	display: inline-block;
	font-family:$font-awesome;
	font-size: 15px;
	font-weight: normal;
	position: absolute;
	text-align: center;
	width:30px;
	height:30px;
	line-height:30px;
	visibility:visible;
}
#product-detail-carousel .owl-prev:before{
		content: "\f053";
}
#product-detail-carousel .owl-next:before{
		content: "\f054";
}
#product-detail-carousel .owl-prev,
#product-detail-carousel .owl-next{
	position:absolute;
	top:50%;
	margin-top:-10px;
	visibility:hidden;
}
#product-detail-carousel .owl-prev{
	left:0;
}
#product-detail-carousel .owl-next{
	right:0;
}
*/


/* 360 IMG */

.tressesenta{
	position: relative;
	/*padding-bottom: 56.25%;*/
	padding-bottom:80.625%;
	overflow: hidden;
	iframe{
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* Box Contact */

.box-contact{
	margin-bottom: 40px;
	background:$dark;
	color:#fff;
	padding:30px;
	@media (min-width: 992px) and (max-width: 1199px){
		padding:30px 20px;
	}
	@media (max-width: 480px){
		padding:30px 20px;
	}
}
.box-contact h3{
	text-transform: uppercase;
	color:#fff;
	margin-top:10px;
	margin-bottom:30px;
	font-weight:300;
	font-family:$font-condensed;
	text-align:center;
	/*line-height:1.25em;*/
}


/* product info */

.product-icons{ 
	margin-bottom:40px;
}
.product-icons-list{
	overflow:hidden;
	padding:0;
	margin:0;
	list-style-type:none;
}
main.page .product-icons-list li{
	display:block;
	float:left;
	background:#fff;
	text-align:center;
	padding:0;
	/*margin:0 0 4px 4px;*/
	margin: 1% 0 1% 1%;
	width: 49.5%;
	img{
		padding:15px 10px;
		margin:0 auto;
	}
	&:first-child { margin-left: 0; }
	a{
		position:relative;
		display:block;
		.hover {
			 background: rgba(39, 154, 215, 0.3);
			 height: 100%;
			 left: 0;
			 opacity: 0;
			 position: absolute;
			 top: 0;
			 width: 100%;
			 z-index: 10;
			 color:#fff;
			 text-align:center;
			 @include transition(all 0.3s ease 0s);
		}
		&:hover{
			.hover {
				 opacity: 1;
			}
		}
		&:before{
			 background: $blue;
			 content: "";
			 display: block;
			 height: 4px;
			 left: 0;
			 position: absolute;
			 bottom: 0;
			 width:100%;
		}
	}
	@media (max-width:479px){
		width: 49%;
		margin: 1% 0 1% 2%;
		&:nth-of-type(2n+1){
			margin-left: 0;
		}
	}

	@media (min-width: 480px) and (max-width: 767px){
		width: 24.25%;
		&:nth-of-type(4n+1){
			margin-left: 0;
		}
	}
	@media (min-width: 768px) and (max-width: 991px){
		width: 19.2%;
		&:nth-of-type(5n+1){
			margin-left: 0;
		}
	}
	@media (min-width: 992px) and (max-width: 1199px){
		width: 15.83%;
		&:nth-of-type(6n+1){
			margin-left: 0;
		}
	}
	@media (min-width: 1200px){
		width: 11.62%;
		&:nth-of-type(8n+1){
			margin-left: 0;
		}
	
	}
}


.product-desc{ 
	margin-bottom:40px;
}
.product-features{ 
	/*margin-bottom:60px;*/
}

/* product-features tabs-menu */

.product-features ul.nav-tabs {
	list-style: none;
	margin: 0;
	padding: 0;
	border:none;
}
.product-features .nav-tabs li {
	display: inline-block;
	@include transition(all 0.3s ease 0s);
	margin: 1px 0 0 0;
	width:33.33%;
}
.product-features .nav-tabs > li.active > a, 
.product-features .nav-tabs > li.active > a:focus, 
.product-features .nav-tabs > li.active > a:hover{
	border: none;
	background-color: $blue;
	color: #fff;
}
.product-features .nav-tabs a {
	display: block;
	padding: 10px;
	text-align:center;
	color: #fff;
	background-color: #a6a7b1;
	border:none;
	border-radius: 0;
	margin-right:1px;
	font-size:15px;
	font-family:$font-condensed;
	text-transform:uppercase;
	@include transition(all 0.3s ease 0s);
}
.product-features .nav-tabs a:hover {
	color: #fff;
	background-color: $blue;
}
/* .product-features .nav-tabs a:last-child {
	color: #fff;
} */
.product-features > ul li:last-child a {
  background-color: $dark;
}
@media (min-width: 768px){
	.product-features ul.nav-tabs {
		padding: 0 0 0 7px;
	}
	.product-features .nav-tabs li {
		-webkit-transform: skewX(-20deg);
		-moz-transform: skewX(-20deg);
		-ms-transform: skewX(-20deg);
		-o-transform: skewX(-20deg);
		transform: skewX(-20deg);
		width:auto;
		margin:0;
	}
	.product-features .nav-tabs span{
		display: block;
		-webkit-transform: skewX(20deg);
		-moz-transform: skewX(20deg);
		-ms-transform: skewX(20deg);
		-o-transform: skewX(20deg);
		transform: skewX(20deg);
	}
	.product-features .nav-tabs a {
			font-size:16px;
			text-align:left;
			padding: 10px 24px;
	}
}

/* product-features tab-content */

.product-features .tab-content{ 
	border:1px solid #ddd;
	padding:30px 15px;
	font-size:15px;
	background-color:#fff;
	@media (min-width: 480px){
		padding:30px 25px;
	}
	@media (min-width: 768px){
		padding:40px;
	}
}
.product-features .tab-content h3{ 
	font-size:18px;
	color:$blue;
}
.product-features .tab-content ul {
    list-style: outside none none;
	 padding-left:20px;
}
.product-features .tab-content li {
    margin-bottom: 5px;
    position: relative;
}
.product-features .tab-content li:before {
    color: #000;
    content: "\f111";
    display: inline-block;
    font-family: $font-awesome;
    font-size: 6px;
	 line-height:6px;
    height: 0;
    left: -16px;
    position: absolute;
    top: 9px;
}
.product-features .tab-content li li:before {
    content: "\f111";
    font-size: 3px;
	 line-height:3px;
    top: 12px;
	 left: -10px;
}

/* product-features features-info */

.features-dimensions{
	font-size:14px;
	th{width: 50px;}
}

.dimensions-table .letter{
	background-color:$blue;
	color:#fff;
	padding:5px 10px;
	display:inline-block;
}
.dimensions-comments{
	color:#777;
	font-size:13px;
	line-height:1.4em;
}


/* product-features features-models */

.features-models {
	line-height:1.4em;
}
.features-models .model{
	margin-bottom:30px;
	font-size:14px;
}
.features-models .model h3{
	font-size:24px;
	border-top:3px solid $blue;
	padding-top:10px;
	margin-bottom:15px;
}
.features-models .model .ref{
	margin-bottom:15px;
}

.features-models .model li{
	margin-bottom:10px;
}

@media (min-width: 768px){
	.features-models .model.col-sm-4:nth-of-type(3n+1){
		clear:both;
	}
}
@media (max-width: 767px){
	.features-models .model.col-sm-4:nth-of-type(2n+1){
		clear:both;
	}
}
@media (max-width: 500px){
	.features-models .model.col-xs-6{
		width:100%;
	}
}

/* product-features features-options */

.table-options{
	line-height:1.4;
	th, td{
		padding: 15px 8px !important;
	}
}
.table-options thead th{
	color:$blue;
	text-transform:uppercase;
	font-size:16px;
	letter-spacing:0.2pt;
	border-bottom:2px solid $blue;
}
.table-options td p:last-child{
	margin-bottom: 0;
}

.table-options td p{
	margin-bottom: 10px;
	&:last-child{
		margin-bottom: 0;
	}
}


/* product-features features-accesories */

.accesories{
	margin-bottom:30px;
	.accesories-img{
		background:#eee;
		padding:20px;
	}
	.accesories-info .title{
		color: $dark;
		font-size:14px;
		line-height: 1.25em;
	}
	.accesories-info .desc{
		font-size:14px;
		line-height:1.4em;
	}

}
@media (min-width: 992px){
	.accesories-list .col-md-3:nth-of-type(4n+1){
		clear:both;
	}
}
@media (min-width: 768px) and (max-width: 991px){
	.accesories-list .col-sm-4:nth-of-type(3n+1){
		clear:both;
	}
}
@media (max-width: 767px){
	.accesories-list .col-xs-6:nth-of-type(2n+1){
		clear:both;
	}
}

@media (max-width: 500px){
	.accesories-list .col-xs-6{
		width:100%;
	}
}


/* product-features features-video */

.features-video #video{
	margin-bottom:30px;
}
.video-list h4{
	text-transform:uppercase;
	font-size:16px;
}

.product-features .tab-content .video-list ul{
	padding:0;
}
.product-features .tab-content .video-list li{
	border-top: 1px solid #ddd;
	margin-bottom:0;
	a {
		color: $dark;
		opacity: 0.3;
		padding: 10px 10px 10px 26px;
		display: inline-block;
		position:relative;
		&:before{
			color: #000;
			content: "\f144";
			display: inline-block;
			font-family: $font-awesome;
			font-size: 16px;
			line-height:24px;
			height: 0;
			left: 3px;
			position: absolute;
		}
	}
	a.active, a:hover {
		opacity: 1;
	}
	&:before{
		content:"";
	}
	&:last-child {
	border-bottom: 1px solid #ddd;
	}
}


/* product-features features-downloads */

.features-downloads{
	padding:15px 0;
	text-align:center;
	.button{
		margin:15px 0;
		display:inline-block;
		width: 400px;
	}
	@media (min-width: 768px){
		padding:30px 0;
		.button{
			margin:15px;
			display:inline-block;
		}
	}
	@media (max-width: 480px){
		padding:0 0 15px 0;
	}
}

.modaal-inner-wrapper{
	@media (max-width: 480px){
		padding-left: 15px;
		padding-right: 15px;
	}
}

.modaal-inline{
	.modaal-container{
		max-width: 450px;
		color:#4d4e53;
	}
	.modaal-content-container{
		padding:0;
		.modal-form-header{
			padding:30px 20px;
			background:$dark-blue;
			h3{
				font-size:20px;
				font-weight:400;
				text-align:center;
				color:#fff;
				margin:0;
				line-height:1.3em;
				text-transform:uppercase;
				span{
					font-weight:700;
					display:block;
				}
			}
			@media (max-width: 480px){
				padding:20px 15px;
				h3{
					font-size:16px;
				}
			}
		}
		.form{
			padding:30px 30px 35px;
			button.btn{
				text-transform: uppercase;
				margin-top: 20px;
			}
			@media (max-width: 480px){
				padding:20px 20px 25px;
			}
		}
	}
}

/* Related Products */

/*#related-products{
	&.page-section{
		padding-top:30px;
	}
}*/

#related-cases{
	/*border-top:1px solid #eee;
	background:#f7f7f7;*/
}


/* VERIFICACION METROLOGICA */

#verificacion-metrologica{
	img.sello-m{
		margin:0 auto;
	}
	h1{
		margin-top:40px;
		margin-bottom:15px;
		text-align:left;
	}
	.subtitle{
		font-size:22px;
		font-weight:300;
	}
}
.verificacion-equipos{
	margin:40px 0 60px;
	padding:60px 0;
	h2{
		color:#fff;
		font-size:26px;
		text-transform:uppercase;
		letter-spacing:0.04em;
	}
	.intro{
		font-size:19px;
	}
}
.verificacion-directiva{
	h3{
		margin-bottom:40px;
	}
	ol{
		color:$blue;
		font-weight:600;
		margin-bottom:30px;
		li span{
			color:$dark;
			font-weight:400;
		}
		&.list-type-letter{
			list-style-type: lower-latin;
		}
	}
	h4{
		margin-bottom:20px;
		margin-top:40px;
	}
}
@media (max-width:767px){
	#verificacion-metrologica{
		img.sello-m{
			max-width:300px;
		}
		h1{
			text-align:center;
		}
		.subtitle{
			text-align:center;
			line-height:1.4em;
		}
	}
	.verificacion-equipos{
		h2{
			font-size:22px;
		}
		.intro{
			font-size:16px;
		}
	}
}

/* SERVICIOS */

.service {
  position: relative;
  margin: 0 15px 30px;
}
.service .row {
  position: relative;
}
.service:before {
  background: #fff;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.service h2 {
  color: $blue;
  margin-bottom: 30px;
  text-transform:uppercase;
  letter-spacing:0.1em;
  line-height:1.4em;
  font-size:16px;
  font-weight:600;
}
.service .service-text {
  background: #fff;
  padding: 40px;
}
.service .service-img {
  padding: 0;
}
.service .service-img img {
  margin: 0 auto;
}
@media (max-width: 480px) {
 
  .service .service-text {
    padding: 40px 24px;
  }
}
@media (min-width: 768px) {
  .service {
    margin: 0;
  }
  .service:before {
    width: 50%;
  }
  /*.service h2 {
	  font-size:16px;
  }*/
  .service .service-text {
    position: absolute;
    padding: 0 0 0 15px;
    height: 100%;
    line-height: 1.4em !important;
    font-size: 15px;
  }
  .service .service-text p {
    line-height: 1.4em !important;
    font-size: 14px;
  }
  .service .service-img {
    float: right;
    padding: 90px 0 90px 40px;
  }
  .service .service-img img {
    position: relative;
  }
  .service.right:before {
    left: auto;
    right: 0;
  }
  .service.right .service-text {
    padding: 0 15px 0 0;
  }
  .service.right .service-img {
    padding: 90px 40px 90px 0;
  }
}
@media (min-width: 992px) {
	
	.service h2 {
	  font-size:18px;
   }

  .service .service-text {
    padding: 0 40px 0 20px;
    font-size: 16px;
    line-height: 1.6em !important;
  }
  .service .service-text p {
    font-size: 16px;
    line-height: 1.6em !important;
  }
  .service .service-img {
    padding: 90px 0 90px 15px;
  }
  .service.right .service-text {
    padding: 0 20px 0 40px;
  }
}


/* SECTORES */

/*#sectors .intro{
	margin-bottom:40px;
}
#sectors-list .box-item{
	margin-bottom:10px;
}
#sectors-list .box-item .title{
	letter-spacing:0.5pt;
}
*/
@media (min-width: 992px) {
	#sectors-list .box-inner .item-info .title{
		font-size:18px;
	}
}
@media (min-width: 480px) and (max-width: 600px){
	#sectors-list .box-inner .item-info .title{
		font-size:18px;
	}
}


/* SECTOR DETALLE */


.sector-detail-img{
	margin-bottom:60px;
}
.sector-detail-text{
	margin-top: 40px;
}
.sector-detail-contact{
	margin-top: 40px;
	margin-bottom:60px;
}


/* SECTOR ALIMENTACION */

#sector-processes {
	padding-top:40px;
	padding-bottom:90px;
	h2.section-title{
		font-family: $font-serif;
		color: $blue;
		/*font-size: 22px;*/
	}
}

/* Grafico Alimentacion */

.effect-shadow{
	position:relative;
	box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
	&:before{
		content:"";
		position:absolute; 
		z-index:-1;
		box-shadow:0 0 20px rgba(0,0,0,0.8);
		top:50%;
		bottom:0;
		left:10px;
		right:10px;
		border-radius:100px / 10px;
	}
	&:after{
		content:"";
		position:absolute; 
		z-index:-1;
		box-shadow:0 0 20px rgba(0,0,0,0.8);
		top:50%;
		bottom:0;
		left:10px;
		right:10px;
		border-radius:100px / 10px;
	}
}
#process-grafico{
	position:relative;
	min-height:290px;
	margin:40px auto;
	max-width:1400px;
	.grafico{
		position:absolute;
		width:100%;
	}
	#process-grafico-nav{
		position:absolute;
		height:100%;
		width:100%;
	}
	#process-grafico-nav ul{
		height: 100%;
		margin: 0;
		border: 0;
	}
	#process-grafico-nav li{
		margin: 0;
		padding: 0;
		height: 100%;
		&.active a {
			color:$blue;
			&:after{
				opacity:1;
			}
			&:before{
				/*background-color: $dark-blue;*/
				background-color: $blue;
			}
		}
	}

	a.process{
		height:100%;
		display:block;
		width: 100%;
		height: 100%;
		position: relative;
		text-align:center;
		color:#9FA3AB;
		background-color: transparent;
		border: none;
		@include transition(all 0.3s ease 0s);
		margin: 0;
		
		-webkit-transform: skewX(-9deg);
		-moz-transform: skewX(-9deg);
		-ms-transform: skewX(-9deg);
		-o-transform: skewX(-9deg);
		transform: skewX(-9deg);
		&:after{
			background: -moz-linear-gradient(top, rgba(39,151,215,0.1) 0%, rgba(241,241,241,0) 100%);
			background: -webkit-linear-gradient(top, rgba(39,151,215,0.1) 0%,rgba(241,241,241,0) 100%);
			background: linear-gradient(to bottom, rgba(39,151,215,0.1) 0%,rgba(241,241,241,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f2797d7', endColorstr='#00f1f1f1',GradientType=0 );
			opacity:0;
			content:"";
			position:absolute;
			width:100%;
			height:100%;
			left:0;
			top:0;
			@include transition(all 0.3s ease 0s);
		}
		&:before{
			/*background-color: $blue;*/
			background-color: #ddd;
			content:"";
			position:absolute;
			width:101%;
			height:3px;
			left:0;
			bottom:0;
			@include transition(all 0.3s ease 0s);
		}
		&:hover, &.active{
			color:$blue;
			&:after{
				opacity:1;
			}
			&:before{
				/*background-color: $dark-blue;*/
				background-color: $blue;
			}
		}
		span{	
			padding:0 20px;
			display: block;
			position:absolute;
			left:0;
			font-weight:500;
			font-family:$font-condensed;
			/*top:65%;*/
			bottom:20px;
			width:100%;
			font-size:16px;
			/*letter-spacing:0.03em;*/
			line-height:1.2em;
			text-transform: uppercase;
			-webkit-transform: skewX(9deg);
			-moz-transform: skewX(9deg);
			-ms-transform: skewX(9deg);
			-o-transform: skewX(9deg);
			transform: skewX(9deg);
		}
	}
	/*.process#p-1{
		width:25.65%;
	}*/
	
	li#p-1{
		width:25.65%;
	}
	li#p-2{
		width:19.42857142%;
	}
	li#p-3{
		width:18.42857142%;
	}
	li#p-4{
		width:19.9%;
	}
	li#p-5{
		width:16.5%;
	}
	@media (max-width:1317px){
		overflow:hidden;
	}
	@media (max-width:767px){
		display:none;
	}
}

/* Resultados Grafico de alimentacion */

.process-results{
	position:relative;
	h3 {
		font-weight:bold;
		font-family:$font-condensed;
		text-transform:uppercase;
		position:relative;
		color:$blue;
		font-size:19px;
		margin-bottom:15px;
		/*padding-left:40px;*/
		&:before{
			content:"";
			display:inline-block;
			font-weight:bold;
			top:-4px;
			margin-right:10px;
		}
	}
	.subtitle{
		font-size:18px;
		margin-bottom:30px;
		line-height: 1.5em;
		font-family: $font-serif;
		font-weight: 300;
	}
	.box-item h4{
		font-size:14px;
		text-transform: uppercase;
		margin:0;
	}
	@media (min-width:768px){
		.process-img{
			display:none;
		}
		h3, .subtitle{
			text-align: center;
		}
	}
	@media (max-width:767px){
		padding-left:60px;
		padding-bottom:40px;
		overflow:hidden;
		&.tab-pane, &.tab-pane.fade{
			display:block;
			opacity: 1;
		}
		&:before{
			content:"";
			display:block;
			border-left:2px solid $blue;
			position:absolute;
			left:20px;
			width: 40px;
			height: 40px;
			text-align: center;
			top:0;
			height:100%;
		}
		h3 {
			margin-top:0;
			padding-left:0;
			padding-top:10px;
			&:before{
				background:$blue;
				position: absolute;
				left:-60px;
				content:"";
				display:inline-block;
				color:#fff;
				top:0;
				padding:10px;
			}
		}
	}
	@media (max-width:480px){
		h3 {
			margin-top:0;
			padding-left:0;
			font-size:22px;
		}
	}
}
#process-1 h3:before{
	content:"1.";
}
#process-2 h3:before{
	content:"2.";
}
#process-3 h3:before{
	content:"3.";
}
#process-4 h3:before{
	content:"4.";
}
#process-5 h3:before{
	content:"5.";
}

/*Subsectores */

#subsectors-list{
	margin-top: 40px;
}
#subsectors {
  padding-bottom: 66px;
  .section-title {
    margin-top: 50px;
  }
}


/* NOTICIAS
-------------------------------------------------- */

#news{
	.page-header{
		margin-bottom:0;
		h1{
			margin-bottom:0;
		}
	}
}
.news-categories{
	padding:5px 0;
	margin-bottom:40px;
	text-align:center;
	li{
		padding:0 20px;
		a{
			text-transform:uppercase;
			font-size:14px;
			font-weight:500;
			letter-spacing:0.1em;
			font-family:$font-condensed;
			color:$dark;
			opacity:0.5;
		}
		a.active{
			color:$blue;
			border-bottom:2px solid $blue;
			opacity:1;
		}
	}
}

.list-news .post{
	margin-bottom:40px;
}
.list-news h3{
	font-size:18px;
	line-height:1.3em;
}
.post-meta{
	overflow:hidden;
	margin-bottom:10px;
	font-size:14px;
	color:#666;
	letter-spacing:0.03em;
	font-weight:500;
}
.post-date{
	margin-right:5px;
}
.list-news .post-img{
	position:relative;
	overflow:hidden;
}
.list-news .post-entry{
	font-size:14px;
	line-height:1.5em;
}
.post-link{
	text-transform:uppercase;
	font-weight:bold;
	font-size:12px;
}
@media (min-width: 768px){
	.list-news .col-sm-4:nth-of-type(3n+1){
		clear:both;
	}
}
@media (max-width: 767px){
	.list-news .col-sm-4:nth-of-type(2n+1){
		clear:both;
	}
}
@media (max-width: 480px){
	.list-news .col-xs-6{
		width:100%;
	}
}


/* Noticias detalle */

#news-detail{
	padding-top:40px;
	padding-bottom:80px;
}
#post-detail h1{
	line-height:1.1em;
	letter-spacing:0;
}

#post-detail .share-links .share-list li a{
	padding:4px 6px;
	font-size:16px;
}
#post-detail .post-entry{
	font-size:20px;
	margin-bottom:40px;
	font-weight:300;
	line-height:1.6em;
	font-family:$font-serif;
}
#post-detail .post-img{
	margin-bottom:40px;
}
#post-detail .post-img img{
	margin:0 auto;
}
#post-detail .post-text{
	margin-bottom:40px;
}
#post-detail #video{
	margin-bottom:40px;
}

@media (max-width: 767px){
	#news-detail{
		padding-top:20px;
		padding-bottom:50px;
	}
	#post-detail .post-entry{
		font-size:16px;
		line-height:1.4em;
	}
}

#related-news{
	border-top:1px solid #eee;
	background:#f7f7f7;
	.top-border, .bottom-border{
		background-color:#f7f7f7;
	}
}
@media (max-width: 991px){
	.list-news .col-md-3:nth-of-type(2n+1){
		clear:both;
	}
}


/* CASOS DE EXITO
-------------------------------------------------- */

#case-studies {
 /*.page-header{
	 margin-bottom:0;
	 h1{
			margin-bottom:0;
		}
 }*/
  position: relative;
  /*min-height: 90vh; Cuando haya filtros*/
  min-height: 60vh;
}
#case-studies:after {
  content: "";
  display: table;
  clear: both;
}

.cases-filters{
	margin-bottom:40px;
	text-align:center;
	li{
		padding:0 27px;
		a{
			text-transform:uppercase;
			font-size:14px;
			font-weight:500;
			letter-spacing:0.1em;
			font-family:$font-condensed;
			color:$dark;
			opacity:0.5;
		}
		a.active{
			color:$blue;
			border-bottom:2px solid $blue;
			opacity:1;
		}
	}
	
}

.cases-gallery{
  padding: 0;
  text-align: justify;
  font-size: 0.1px;
  -webkit-backface-visibility: hidden;
}
.cases-gallery:after{
  content: '';
  display: inline-block;
  width: 100%;
}
.cases-gallery .mix,
.cases-gallery .gap{
  display: inline-block;
  width: 49%;
}
.cases-gallery .mix{
  text-align: left;
  margin-bottom: 2%;
  display: none;
}
.cases-gallery .mix:after{
  content: attr(data-myorder);
  color: white;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  /*padding: 4% 6%;*/
  font-weight: 700;
}
.cases-gallery .mix:before{
  content: '';
  display: inline-block;
  /*padding-top: 60%;*/
}

@media all and (min-width: 992px){
  .cases-gallery .mix,
  .cases-gallery .gap{
   /* width: 23.5%;*/
	width: 32.5%;
  }
}
@media all and (max-width: 600px){
  .cases-gallery .mix,
  .cases-gallery .gap{
    width: 100%;
  }
}

#case-studies-detail{
	padding-bottom:80px;
	.lead{
		font-size:20px;
		margin-top:40px;
		margin-bottom: 40px;
	}
	.cases-detail-img{
		margin-bottom: 40px;
	}
	.text{
		margin-bottom:40px;
	}
	#video{
		margin-bottom:40px;
	}
}

/* CONTACTO
-------------------------------------------------- */

#contact{
	padding-top:45px;
}

#contact-form{
	margin-bottom:60px;
	h1{
		text-align:left;
	}
}
#contact-form .form-control{
	/*background:#F5F5F5;
	border-color:#F5F5F5;*/
}
#contact-form .form .btn{
	text-transform:uppercase;
	margin-top:24px;
	max-width:300px;
}

#contact-location{
}

.location{
	padding:20px 0 20px 60px;
	margin-bottom:0;
	h3{
		position:relative;
		text-transform:uppercase;
		font-size:18px;
		margin-bottom:20px;
		color:$blue;
		&:before{
			background: url(../imagenes/marker-map.png) no-repeat;
			content: "";
			height: 27px;
			left: -40px;
			position: absolute;
			top: 0;
			width: 22px;
		}
	}
}

.location .map{
	margin-bottom:24px;
}
.location address{
	font-size:15px;
}



/* AVISO LEGAL Y POLITICA PRIVACIDAD
-------------------------------------------------- */




/* MAPA WEB
-------------------------------------------------- */

.list-site-map{
	list-style-type:none;
	/*padding:0;*/
}
.list-site-map li a{
	text-transform:uppercase;
	font-weight:bold;
	color:$dark;
}
.list-site-map li a:hover{
	color:$blue;
}
.list-site-map li ul {
	padding-left:25px;
	margin-top: 10px;
	margin-bottom:30px;
	list-style-type:none;
}
.list-site-map li ul li {
	margin-bottom:10px;
	position:relative;
}
.list-site-map li ul li a{
	text-transform: none;
	font-weight:normal;
}
.list-site-map li:before {
    content: "";
}
.list-site-map li ul li:before {
    content: "-";
	 position:absolute;
	 left:-15px;
	 top:0;
}


/* ERROR 404
-------------------------------------------------- */

#error-404{
	padding-top:45px;
	text-align:center;
	h1{
		margin-top:40px;
		margin-bottom:20px;
	}
	.subtitle{
		font-size:20px;
		font-weight:300;
	}
	h3{
		line-height:1.2em;
		margin:40px 0 30px;
	}
	.btn{
		margin:10px;
	}
	
	@media (max-width: 767px){
		padding-top:20px;
	}
}


/* BUSCADOR
-------------------------------------------------- */




/* MAPS
-------------------------------------------------- */

.map-fluid{
	position: relative;
	padding-bottom: 75%;
	/*padding-top: 30px;*/
	height:0 !important;
	overflow: hidden;
	/*margin-bottom:20px;*/
}
.map-fluid iframe{
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height:100% !important;
	z-index:1;
}

/* COOKIES POP UP
-------------------------------------------------- */

#cookies-popup {
	background: rgba(48,48,48, .65);
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1010;
	font-size: 1.5rem;
	.container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@media (max-width: 767.99px){
			min-width: 90%;
		}
		.cookies-popup-text{
			background: white;
			padding: 2rem;
			line-height: 2;
			.buttons {
				.btn {
					font-size: 1.5rem;
				}
			}
		}
	}
	&.in-cookies-section {
		top: auto;
		background: rgba(48,48,48, .95);
		.container {
			position: relative;
			top: 0;
			left: 0;
			transform: none;
			width: 100%;
			.cookies-popup-text{
				background: none;
				color: white;
				line-height: 1.6;
			}
		}
	}
}

.cookies, #modal-cookies-popup {
	font-size: 1.5rem;
	.location {
		max-width: 650px;
		margin: 4rem auto;
		padding: 3rem;
		ul {
			padding-left: 0;
			li {
				padding: 1rem 0;
				border-bottom: 1px solid $blue;
				&:last-of-type {
					border: 0;
				}
				label {
					font-size: 1.5rem;
					font-weight: bold;
				}
			}
		}
		.btn {
			font-size: 1.5rem;
			font-weight: bold;
			text-transform: uppercase;
			margin: 0 auto;
			display: block;
		}
	}
}

#modal-cookies-popup {
	background: rgba(48,48,48, .65);
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1015;
	display: none;
	.close {
		position: absolute;
		top: 10px;
		right: 30px;
		font-size: 60px;
		color: white;
		text-shadow: none;
		opacity: .75;
		&:hover
		{
			opacity: .45;
		}
	}
	.location{
		width: 90%;
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		background: white;
	}
}

.cookies {
	.location {
		border: 1px solid $blue;
	}
}


.video-no-cookies {
	position: absolute;
	width: 100%;
	height: 100%;
	background: black;
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 90%;
		text-align: center;
		color: white;
		a{
			color: #FFF;
			text-decoration: underline;
			&:hover {
				opacity: .75;
				text-decoration: none;
			}
		}
	}
}


/* BTN EFFECTS
-------------------------------------------------- */

.button {
    backface-visibility: hidden;
    background-color: $dark;
    border: medium none;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 18px;
    font-weight: 500;
    height: 70px;
    line-height: 20px;
    margin: 0 auto 24px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    text-align: center;
    transition: all 0.25s ease-in 0s;
    width: 300px;
}
.button-sm {
    font-size: 16px;
    height: 50px;
    padding: 18px;
    width: 200px;
}
.button-sm span {
    top: -2px;
}
.button-wide {
    width: 380px;
}
.button.btn-block {
    width: 100%;
}
.button:hover, .button:focus {
   background-color: $dark;
	color: #fff;
}
.button[class*="ui-"] span {
    backface-visibility: hidden;
    display: inline-block;
    height: 100%;
    position: relative;
    transition: all 0.25s ease-in 0s;
    width: 100%;
}
.button[class*="ui-"]:hover span {
    color: transparent;
    transform: translateX(200%);
}

.button:after {
    content: "";
    position: absolute;
    transition: all 0.25s ease-in 0s;
    z-index: -1;
}
.button:before {
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-awesome;
    font-size: 1.1em;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    height: 100%;
    left: -100%;
    line-height: 1;
    padding: 20px;
    position: absolute;
    text-transform: none;
    top: 0;
    transition: all 0.25s ease-in 0s;
    width: 100%;
}
.button:hover:before {
    left: 0;
}
.button-sm:before {
    padding: 15px;
}
.button.button-icon {
    padding-left: 70px;
    position: relative;
}
.button.button-icon:before {
    background-color: rgba(0, 0, 0, 0.25);
    height: 70px;
    left: 0;
    padding: 20px;
    position: absolute;
    top: 0;
    width: 70px;
}
.button.button-icon[class*="ui-"] span {
    height: 70px;
    left: 0;
    padding: 24px 24px 24px 70px;
    position: absolute;
    top: 0;
}
.button.button-icon:hover:before {
    width: 100%;
}
.button.blue {
    background-color: $blue;
}
.button.blue:hover, .button.blue:focus  {
   background-color: $dark-blue;
	color: #fff;
}
.button.dark-blue {
    background-color: $dark-blue;
}
.button.dark-blue:hover, .button.dark-blue:focus {
   background-color: darken($dark-blue, 5%);
	color: #fff;
}
.button.dark {
    background-color: $dark;
}
.button.dark:hover {
    background-color: lighten($dark, 5%);
}
.button.orange {
    background-color: $orange;
}
.button.orange:hover {
    background-color: darken($orange, 15%);
}

.ui-download:before{
	content: url(../imagenes/download.svg);
}
.ui-pdf:before{
	content: url(../imagenes/pdf.svg);
}
.ui-envelope:before{
	content: "\f0e0";
}
.ui-rss:before{
	content: "\f09e";
}

@media (max-width: 400px){
	.button {
		width:100%;
		font-size:16px;
	}
}


/* PRINT PDF
-------------------------------------------------- */

#print{
	.container{
		width: 970px !important;
	}
	header{
		img{margin:20px auto;}
		/*padding: 20px;*/
		text-align: center;
	}
}

/* QUIENES SOMOS > Trayectoria
-------------------------------------------------- */
.texto-trayectoria {
	@media (min-width: 992px) {
		column-count: 2;
		column-gap: 30px;
	}
}