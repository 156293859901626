//
// Navbars
// --------------------------------------------------

.navbar{
	margin-bottom:0;
}
.navbar.navbar-inverse {
  background: $dark;
  /*border-color: $dark-blue;*/
  border:none;
  color: #b3b3b3;
}
.navbar.navbar-inverse .navbar-collapse {
  background-color: $dark;
  /*border-color: $dark-blue;*/
  border:none;
}
.navbar .navbar-nav{
	margin:0;
}
.navbar.navbar-inverse .navbar-text {
  color: #cccccc;
}
.navbar.navbar-inverse a.navbar-link {
  color: #cccccc;
  background-color: transparent;
}
.navbar.navbar-inverse a.navbar-link:hover,
.navbar.navbar-inverse a.navbar-link:focus {
  color: #ffffff;
  background-color: transparent;
}
.navbar.navbar-inverse .navbar-brand {
  color: #cccccc;
  background-color: $dark;
}
.navbar.navbar-inverse .navbar-brand:hover,
.navbar.navbar-inverse .navbar-brand:focus {
  color: #ffffff;
  background-color: $dark;
}
.navbar.navbar-inverse .navbar-toggle {
  border-color: #666666 !important;
  background-color: $dark;
  border:none;
}
.navbar.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}
.navbar.navbar-inverse .navbar-toggle:hover,
.navbar.navbar-inverse .navbar-toggle:focus {
  border-color: #666666 !important;
  background-color: $dark !important;
}
.navbar.navbar-inverse .navbar-toggle:hover .icon-bar,
.navbar.navbar-inverse .navbar-toggle:focus .icon-bar {
  background-color: #ffffff;
}
.navbar.navbar-inverse .navbar-nav>li.divider {
  background-color: #404040;
}
.navbar.navbar-inverse .btn-default {
  color: #cccccc;
  background-color: #454545;
  border-color: #666666;
}
.navbar.navbar-inverse .btn-default:hover,
.navbar.navbar-inverse .btn-default:focus,
.navbar.navbar-inverse .btn-default:active,
.navbar.navbar-inverse .btn-default.active {
  color: #ffffff;
  background-color: #3d3d3d;
  border-color: #666666;
}
.navbar.navbar-inverse .form-control {
  color: #cccccc;
  background-color: #454545;
  border-color: #666666;
}
.navbar.navbar-inverse .form-control:focus {
  -webkit-box-shadow: inset 0 1px 1px transparent, 0 0 5px #666666;
  box-shadow: inset 0 1px 1px transparent, 0 0 5px #666666;
}
.navbar.navbar-inverse input::-webkit-input-placeholder,
.navbar.navbar-inverse textarea::-webkit-input-placeholder {
  color: rgba(204,204,204,0.3);
}
.navbar.navbar-inverse input::-moz-placeholder,
.navbar.navbar-inverse textarea::-moz-placeholder {
  color: rgba(204,204,204,0.3);
}
.navbar.navbar-inverse input:-ms-input-placeholder,
.navbar.navbar-inverse textarea:-ms-input-placeholder {
  color: rgba(204,204,204,0.3); 
}
.navbar.navbar-inverse input:-moz-placeholder,
.navbar.navbar-inverse textarea:-moz-placeholder {
  color: rgba(204,204,204,0.3);
}
.navbar.navbar-inverse .navbar-nav > li[class*="dropdown"].dropdown-onhover:hover > a,
.navbar.navbar-inverse.dropdown-onhover .navbar-nav > li:hover > a[class*="dropdown"] {
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav>li>a {
  color: #fff;
  background-color: $dark;
}
.navbar.navbar-inverse .navbar-nav>li:hover>a {
  color: #ffffff;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav>li.active>a {
  color: #ffffff;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav>li.active:hover>a {
  color: #ffffff;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav>li.open>a {
  color: #ffffff;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav>li.open:hover>a {
  color: #ffffff;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav>li.disabled>a {
  color: #999999;
  background-color: $dark;
}
.navbar.navbar-inverse .navbar-nav>li.disabled:hover>a {
  color: #999999;
  background-color: $dark;
}
.navbar.navbar-inverse .dropdown-menu {
  background: $dark-blue;
  color: #fff;
  border-color: $dark-blue;
  -webkit-box-shadow: 0 9px 12px #2b2b2b;
  box-shadow: 0 9px 12px #2b2b2b;
}
.navbar.navbar-inverse .navbar-nav>li[class*="dropdown"].dropdown-onhover:hover,
.navbar.navbar-inverse .navbar-nav.dropdown-onhover>li[class*="dropdown"]:hover,
.navbar.navbar-inverse.dropdown-onhover .navbar-nav>li[class*="dropdown"]:hover {
  background-color: $dark-blue;
}
.navbar.navbar-inverse a>span.desc {
  color: #e3e3e3;
  display:none;
}
.navbar.navbar-inverse a:hover>span.desc {
  color: #e3e3e3;
}
.navbar.navbar-inverse .dropdown-menu .btn-default {
  color: #fff;
  background-color: #555555;
  border-color: #777777;
}
.navbar.navbar-inverse .dropdown-menu .btn-default:hover,
.navbar.navbar-inverse .dropdown-menu .btn-default:focus,
.navbar.navbar-inverse .dropdown-menu .btn-default:active,
.navbar.navbar-inverse .dropdown-menu .btn-default.active {
  color: #f7f7f7;
  background-color: $dark-blue;
  border-color: #777777;
}
.navbar.navbar-inverse .dropdown-menu li.dropdown-header {
  color: #fff;
}
.navbar.navbar-inverse .dropdown-menu>li>a {
  color: #fff;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .dropdown-menu>li>a:hover {
  color: #f7f7f7;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav>li .dropdown-menu:not([class*="collaps"]) {
  background-color: $dark-blue;
  color: #fff;
  border-color: $dark-blue;
  -webkit-box-shadow: 0 9px 12px #2b2b2b;
  box-shadow: 0 9px 12px #2b2b2b;
}
.navbar.navbar-inverse.navbar-fixed-bottom .navbar-nav>li .dropdown-menu:not([class*="collaps"]) {
  -webkit-box-shadow: 0 -9px 12px #2b2b2b;
  box-shadow: 0 -9px 12px #2b2b2b;
}
.navbar.navbar-inverse .dropdown-menu .form-control {
  color: #fff;
  background-color: #555555;
  border-color: #777777;
}
.navbar.navbar-inverse .dropdown-menu .form-control:focus {
  -webkit-box-shadow: inset 0 1px 1px transparent, 0 0 5px #777777;
  box-shadow: inset 0 1px 1px transparent, 0 0 5px #777777;
}
.navbar.navbar-inverse .dropdown-menu input::-webkit-input-placeholder,
.navbar.navbar-inverse .dropdown-menu textarea::-webkit-input-placeholder {
  color: rgba(221,221,221,0.3);
}
.navbar.navbar-inverse .dropdown-menu input::-moz-placeholder,
.navbar.navbar-inverse .dropdown-menu textarea::-moz-placeholder {
  color: rgba(221,221,221,0.3);
}
.navbar.navbar-inverse .dropdown-menu input:-ms-input-placeholder,
.navbar.navbar-inverse .dropdown-menu textarea:-ms-input-placeholder {
  color: rgba(221,221,221,0.3);
}
.navbar.navbar-inverse .dropdown-menu input:-moz-placeholder,
.navbar.navbar-inverse .dropdown-menu textarea:-moz-placeholder {
  color: rgba(221,221,221,0.3);
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu>li[class*="-onhover"],
.navbar.navbar-inverse .navbar-nav .dropdown-menu>li[class*="-onclick"] {
  color: #fff;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu>li[class*="-onhover"]:hover>a,
.navbar.navbar-inverse .navbar-nav .dropdown-menu>li[class*="-onclick"]:hover>a {
  color: #f7f7f7;
  background-color: darken($dark-blue, 10%);
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li:not(.list-group) > a {
  color: #fff;
  background-color: transparent;
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li:not(.list-group) > a:hover {
  color: #f7f7f7;
  background-color: darken($dark-blue, 10%);
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li.active > a {
  color: #f7f7f7;
  background-color: darken($dark-blue, 10%);
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li.active > a:hover {
  color: #f7f7f7;
  background-color: darken($dark-blue, 10%);
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li[class*="-onhover"]>a:hover {
  color: #f7f7f7;
  background-color: darken($dark-blue, 10%);
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li.open > a,
.navbar.navbar-inverse .navbar-nav .dropdown-menu li:not(.no-fix) > a.dropdown-toggle:not(.collapsed) {
  color: #f7f7f7;
  background-color: darken($dark-blue, 10%);
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li.open > a:hover,
.navbar.navbar-inverse .navbar-nav .dropdown-menu li:not(.no-fix) > a.dropdown-toggle:not(.collapsed):hover {
  color: #f7f7f7;
  background-color: darken($dark-blue, 10%);
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li.disabled > a {
  color: #777777;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li.disabled > a>span.desc {
  color: #777777;
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li.disabled > a:hover>span.desc {
  color: #777777;
}
.navbar.navbar-inverse .navbar-nav .dropdown-menu li.disabled > a:hover {
  color: #777777;
  background-color: $dark-blue;
}
.navbar.navbar-inverse .navbar-nav li:not(.dropdown)>.dropdown-menu.bordered:not(.no-padding):after,
.navbar.navbar-inverse .navbar-nav li:not(.dropdown)>.dropdown-grid-wrapper>.dropdown-menu.bordered:not(.no-padding):after {
  outline-color: #3F81A6;
}
.navbar.navbar-inverse .dropdown-menu li.divider,
.navbar.navbar-inverse .v-divided>*:before,
.navbar.navbar-inverse .v-divided>*:after,
.navbar.navbar-inverse .h-divided>*:after,
.navbar.navbar-inverse .h-divided>*:first-child:before,
.navbar.navbar-inverse .h-divider:after {
  background-color: #3F81A6;
}
.navbar.navbar-inverse .divided>*:before {
  border-color: #3F81A6;
}



/* Tabs Nav Styles */

#desktop-nav .nav-tabs{
	border: none;
}
#desktop-nav .dropdown-full.open .dropdown-menu{
	display:flex;
	padding:0;
}
#desktop-nav .nav-tabs.nav-tabs-first{
	/*background:#0b618f;*/
	background:#106a9a;
}

#desktop-nav .tab-pane {width: 100%!important;}f.menu-product-item
#desktop-nav .nav-tabs>li{padding: 0;margin-bottom: 0;}
#desktop-nav .nav-tabs>li:not([class*="col-"]) {width: 100%!important;}
#desktop-nav .nav-tabs li a{ cursor: pointer !important; position:relative;}
#desktop-nav .nav-tabs>li>a, 
#desktop-nav .nav-tabs>li.active>a, 
#desktop-nav .nav-tabs>li.active>a:hover, 
#desktop-nav .nav-tabs>li.active>a:focus {margin-right: 0;border-radius: 0; border: none;}
@media (min-width: 992px) {
	.navbar .dropdown-toggle {/*margin-bottom: -1px;border-bottom: 1px solid transparent!important;*/}
	.navbar.navbar-fixed-bottom .dropdown-toggle {margin-top: -1px;border-top: 1px solid transparent!important;}
}
@media (max-width: 991px) {
  #desktop-nav .nav-tabs {width: 100%!important;border-right: none!important;border-bottom: 1px solid #555!important;}
  #desktop-nav .tab-content {width: 100%!important;padding-left: 0!important;}
}


.menu-products{
	position:absolute;
	height:100%;
	right:0;
	top:0;
	background-color: lighten($dark-blue, 2%);
}

.menu-products{
	text-align:center;
}
.menu-products .col-md-4{
	border-right:1px solid rgba(255, 255, 255, 0.14);
	border-bottom:1px solid rgba(255, 255, 255, 0.14);
}
.menu-product-item{
	display:block;
	padding:10px 20px;
	&:hover{
		opacity:0.8;
		/*background-color:$dark-blue;*/
	}
}
.menu-product-item img{
	margin:0 auto;
	max-width:100px;
}
a.menu-product-item h3{
	font-size:12px;
	color:#fff;
	text-align:center;
	font-weight:600;
	padding:15px 0;
	line-height:1.4em;
	text-transform:uppercase;
}
.menu-product-link{
	padding:20px;
	display:block;
	font-size:13px;
	color:#fff;
	text-align:center;
	font-weight:700;
	line-height:1.4em;
	text-transform:uppercase;
	&:hover{
		color:#fff;
		opacity:0.6;
	}
}


#desktop-nav .nav-tabs>li.has-children a:before{
	position:absolute;
	content:"\f105";
	font-family:"FontAwesome", sans-serif;
	color:#fff;
	font-size:16px;
	line-height:16px;
	right:10px;
	top:50%;
	margin-top:-8px;
}


/* Short dropdown Styles */

.navbar .navbar-nav > li.dropdown-short > .dropdown-menu{
	padding:0;
}
.navbar .navbar-nav > li.dropdown-short > .dropdown-menu li{
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.navbar .navbar-nav > li.dropdown-short > .dropdown-menu li:last-child{
	border-bottom: none;
}
.navbar .navbar-nav > li.dropdown-short > .dropdown-menu li a{
	padding:12px 20px;
}